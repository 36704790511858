import styled from '@emotion/styled'
import { CloseCircleIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { css } from 'theme-ui'
import { variant } from '../../helpers'
import { useMergeRefs } from '../../hooks'
import { Text } from '../Text'

export type InputProps = {
  name?: string
  variant?: 'default' | 'muted' | 'heading' | 'underline'
  placeholder?: string
  unitLabel?: string
  invalid?: boolean
  isClearable?: boolean
  prefix?: React.ReactElement | string | null
  disabled?: boolean
  value?: string
  type?: string
  className?: string
  bold?: boolean
  readOnly?: boolean
  autoFocus?: boolean
  autoComplete?: string
  onChange?: (change: string) => void
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
}

type InputContainerProps = {
  variant: 'default' | 'muted' | 'heading' | 'underline'
  invalid: InputProps['invalid']
}

const InputContainer = styled.div<InputContainerProps>(
  css({
    display: 'inline-flex',
    transition: 'all 100ms linear',
    fontFamily: 'body',
  }),
  ({ invalid, theme }) => {
    const outlineColor = invalid ? theme.colors['system-background-warning'] : theme.colors['background-medium']
    const blueOutlineColor = invalid ? theme.colors['system-background-warning'] : theme.colors['accent-background']

    return variant({
      prop: 'variant',
      variants: {
        default: {
          borderRadius: 'default',

          bg: 'background-light',
          boxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}, ${theme.shadows.defaultTransparent}`,
          '&:hover': {
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.defaultTransparent}`,
          },
          '&:focus-within': {
            bg: 'background',
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
          },
        },
        muted: {
          borderRadius: 'default',

          bg: 'transparent',
          boxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}, ${theme.shadows.defaultTransparent}`,
          '&:hover': {
            bg: 'background-light',
            boxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}, ${theme.shadows.defaultTransparent}`,
          },
          '&:focus-within': {
            bg: 'background',
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
          },
        },
        heading: {
          borderRadius: 'default',
          fontSize: 2,

          bg: 'transparent',
          boxShadow: `inset 0 0 0 0 ${outlineColor}, ${theme.shadows.defaultTransparent}`,
          '&:hover': {
            bg: 'background-light',
            boxShadow: `inset 0 0 0 0 ${outlineColor}, ${theme.shadows.defaultTransparent}`,
          },
          '&:focus-within': {
            bg: 'background',
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
          },
        },
        underline: {
          fontSize: 3,
          fontWeight: 500,
          lineHeight: 1.4,
          fontFamily: 'heading',

          bg: 'transparent',
          boxShadow: `inset 0 ${invalid ? '-2px' : '0'} 0 0 ${blueOutlineColor}`,
          '&:hover': {
            boxShadow: `inset 0 -1px 0 ${outlineColor}`,
          },
          '&:focus-within': {
            boxShadow: `inset 0 -2px 0 ${blueOutlineColor}`,
          },
        },
      },
    })
  },
)

const InputWrapper = styled.div<{ variant?: InputProps['variant']; bold?: boolean }>(
  css({
    flexGrow: 1,
    '> input': {
      width: '100%',
      lineHeight: 'body',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
    },
    '&::after': {
      content: `attr(data-value)`,
      display: 'block',
      visibility: 'hidden',
      overflowWrap: 'anywhere',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      height: 0,
      overflow: 'hidden',
    },
  }),
  ({ bold }) =>
    bold &&
    css({
      '> input, &::after': {
        fontWeight: 'bold',
      },
    }),
  variant({
    prop: 'variant',
    variants: {
      default: {
        '> input, &::after': {
          paddingX: 1.5,
        },
        '> input': {
          height: 5,
        },
      },
      muted: {
        '> input, &::after': {
          paddingX: 1,
        },
        '> input': {
          height: 4,
        },
      },
      heading: {
        '> input, &::after': {
          px: 1,
        },
        '> input': {
          py: 1,
        },
      },
      underline: {
        '> input, &::after': {
          px: 0,
        },
        '> input': {
          py: 1.5,
        },
      },
    },
  }),
)

const Indicators = styled.div<{ variant?: InputProps['variant'] }>(
  css({
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  }),
  variant({
    prop: 'variant',
    variants: {
      default: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${InputWrapper} + &`]: {
          paddingRight: 1,
        },
        [`&:first-of-type`]: {
          paddingLeft: 1,
        },
      },
      muted: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${InputWrapper} + &`]: {
          paddingRight: 1,
        },
        [`&:first-of-type`]: {
          paddingLeft: 1,
        },
      },
      heading: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${InputWrapper} + &`]: {
          paddingRight: 1,
        },
        [`&:first-of-type`]: {
          paddingLeft: 1,
        },
      },
      underline: {
        [`&:first-of-type`]: {
          paddingRight: 1,
        },
      },
    },
  }),
)

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      unitLabel,
      isClearable,
      prefix,
      disabled,
      className,
      onChange,
      onKeyPress,
      onKeyDown,
      placeholder,
      value,
      type,
      variant = 'default',
      invalid = false,
      bold,
      readOnly,
      autoFocus,
      autoComplete,
      onBlur,
      onFocus,
    },
    inputRef,
  ) => {
    const ref = useRef<HTMLInputElement>(null)
    const forwardRef = useMergeRefs([ref, inputRef])
    const uncontrolledMode = value == null
    const [fallbackValue, setFallbackValue] = useState(value)
    const effectiveValue = value ?? fallbackValue

    useEffect(() => {
      if (value !== undefined) {
        setFallbackValue(value)
      }
    }, [value])

    useEffect(() => {
      if (autoFocus && (!disabled || !readOnly)) {
        ref.current?.focus()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClear = useCallback(() => {
      if (uncontrolledMode) {
        setFallbackValue('')
      }
      onChange?.('')
    }, [onChange, uncontrolledMode])
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        if (uncontrolledMode) {
          setFallbackValue(value)
        }
        onChange?.(event.target.value)
      },
      [onChange, uncontrolledMode],
    )
    const handleIndicatorsClick = useCallback(() => {
      ref.current?.focus()
    }, [])

    const renderIndicators = () =>
      unitLabel || isClearable ? (
        <Indicators variant={variant} onClick={handleIndicatorsClick}>
          {isClearable ? (
            <CloseCircleIcon
              width={2}
              height={2}
              display="block"
              color="text-light"
              onClick={handleClear}
              style={{ cursor: 'pointer' }}
            />
          ) : null}
          {unitLabel ? <Text sx={{ color: 'text-light' }}>{unitLabel}</Text> : null}
        </Indicators>
      ) : null

    const renderPrefix = () => {
      return prefix ? (
        <Indicators variant={variant} onClick={handleIndicatorsClick}>
          {prefix}
        </Indicators>
      ) : null
    }

    // in fact, different browsers use different symbols to mask passwords. BUT I think it does not make much sense
    // to use dynamic-width inputs for passwords in general. So it does not matter.
    const maskedValue = type === 'password' ? _.repeat('•', effectiveValue?.length || 0) : effectiveValue
    return (
      <InputContainer variant={variant} invalid={invalid} className={className}>
        {renderPrefix()}
        <InputWrapper variant={variant} bold={bold} data-value={maskedValue || placeholder}>
          <input
            ref={forwardRef}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            value={effectiveValue}
            type={type}
            name={name}
            autoComplete={autoComplete}
            size={1}
          />
        </InputWrapper>
        {renderIndicators()}
      </InputContainer>
    )
  },
)

Input.displayName = 'Input'
