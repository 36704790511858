import React, { useCallback } from 'react'

/**
 * You must treat refs as a deps array of a regular React hook. Meaning that:
 * 1. Length of the array must be constant, so better not to use spread operator or pass an arbitrary array variable;
 * 2. You may assume that given the same list of refs, result of the hook is the same;
 *
 * Also given that refs in React must be stable to prevent infinite render loops, you must make sure that you pass
 * stable refs to this hook. For callback refs, please use `useCallback`.
 */
export const useMergeRefs = <T>(refs: React.ForwardedRef<T>[]) => {
  return useCallback((val: T | null) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(val)
      } else if (ref) {
        ref.current = val
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs)
}
